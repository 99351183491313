import React from "react";
import { Container, Title, Text, StyledLink } from "./styles";
import PageContainer from "../PageContainer";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";

const Emergencies = () => {
  return (
    <>
      <Breadcrumb pageTitle="Emergencies">
        <BreadcrumbItem
          Link="/patient-information/emergencies"
          Text="Emergencies"
        />
      </Breadcrumb>

      <PageContainer>
        <Container>
          <Text>
            <i>True orthodontic emergencies are rare</i>, however, if you do
            have an emergency, please remember that the team at Gippsland
            Orthodontics are here for you. As a general rule, you should{" "}
            <i>
              call our office when you experience severe pain or have a painful
              appliance problem you can&#39;t take care of yourself
            </i>
            . We&#39;ll be able to schedule an appointment to resolve the
            problem.
          </Text>
          <Text>
            You might be surprised to learn that you may be able to solve many
            problems yourself temporarily until you can get to our clinic.{" "}
            <i>
              Please understand that most emergencies will be seen during office
              or school hours
            </i>
            . Which means it’s important to ask yourself whether the issue is
            minor of requires immediate attention.
          </Text>
          <Title>
            What emergencies may arise during orthodontic treatment?
          </Title>
          <Title>
            <i>General Soreness</i>
          </Title>
          <Text>
            When you get your braces on, you may feel general soreness in your
            mouth. Teeth may also be tender to biting pressures for three to
            five days. Stick to a soft diet until your teeth do not hurt to
            chew. If the tenderness is severe, take Paracetamol or a similar
            analgesic taken for headaches or a comparable pain. Aspirin and
            Ibuprofen (Nurofen) actually slow the tooth movement, so it is not
            advisable to use them frequently while wearing braces.
          </Text>
          <Text>
            Irritated gums and other sore spots can be relieved by rinsing your
            mouth with a warm salt-water mouthwash. Dissolve one teaspoonful of
            salt in a quarter of a cup warm water and rinse your mouth
            vigorously.
          </Text>
          <Text>
            The lips, cheeks, and tongue may become irritated for one to two
            weeks as they learn a new posture and become accustomed to the
            surface of the braces. You can put wax on the braces to lessen this.
          </Text>
          <Text>
            Lastly, some orthodontic wires become softer and reduce the pressure
            on teeth with cold foods and drinks. It may be advised to drink cold
            water, sugar-free cold drinks and cold foods low in sugar to help
            reduce the pain.
          </Text>
          <Title>
            <i>Loose Brace or Band</i>
          </Title>
          <Text>
            Braces are usually attached to the centre of each tooth using a
            special adhesive. Usual causes of brackets becoming knocked off
            involve eating foods you are not meant to or by suffering an impact
            to the mouth. If your brace or band is still attached to the wire,
            you should leave it in place and put wax on it if needed for
            comfort. If the bracket or band can be removed easily, place it in
            an envelope and save it to bring to your next appointment.
          </Text>
          <Title>
            <i>Loose Wire</i>
          </Title>
          <Text>
            Using a pair of tweezers try to put your wire back into place. It is
            okay to use a piece of floss to tie the wire into place; tie the
            floss around the bracket in place of the missing coloured module. If
            you cannot put the wire into a comfortable position and covering the
            end with wax doesn&#39;t resolve the concern, use a clean small
            fingernail clipper to clip the wire behind the last tooth to which
            it is securely fastened. Place wax on the end of the wire if it is
            still sharp.
          </Text>
          <Title>
            <i>Poking Wire</i>
          </Title>
          <Text>
            Sometimes the end of a wire can work itself out of place, cause
            irritation and discomfort. If this happens, and you cannot carefully
            push the wire safely against the tooth with a pencil eraser, then
            use relief wax to cover the wire.
          </Text>
          <Title>
            <i>Food caught between teeth</i>
          </Title>
          <Text>
            This is not an emergency and should be handled by carefully flossing
            with a toothpick, dental floss or water pick.
          </Text>
          <Title>
            <i>Anything else?</i>
          </Title>
          <Text>
            After alleviating your discomfort, it is very important that you
            call our office as soon as possible to schedule a time to repair the
            problem. Allowing your appliance to remain damaged for an extended
            period of time may result in disruptions to your treatment plan and
            extended treatment times. If any of these issues occur to you, with
            the exception of trapped food, you should contact our office and
            arrange an appointment, by{" "}
            <StyledLink to="/contact">clicking here</StyledLink>. However, if
            the issue is serious and requires immediate attention, visit an
            emergency care clinic and contact Gippsland Orthodontics
            immediately.
          </Text>
        </Container>
      </PageContainer>
    </>
  );
};

export default Emergencies;
