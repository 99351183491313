import React from "react";
import Layout from "../../components/layout";
import Emergencies from "../../components/patient-information/emergencies";
import SEO from "../../components/seo";

const EmergenciesPage = () => (
  <Layout>
    <SEO title="Emergencies" />
    <Emergencies />
  </Layout>
);

export default EmergenciesPage;
